import PropTypes from "prop-types";
import React from "react";

export default function UiHeading({ children }) {
  return(
    <h2 className="max-w-screen-sm my-4 pl-4 m-auto text-xl text-gray-600 font-bold">{children}</h2>
  )}

  UiHeading.propTypes = {
    children: PropTypes.node.isRequired,
  };