import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Section from "../components/section";
import MainHeading from "../components/main-heading";
import UiLink from "../components/ui-link";
import UiHeading from "../components/ui-heading";
import UiLinkContainer from "../components/ui-link-container";

import addIcon from "../images/icons/add.svg";
import adIcon from "../images/icons/ad.svg";
import fixIcon from "../images/icons/fix.svg";
import buyIcon from "../images/icons/buy.svg";
import bsodIcon from "../images/icons/bsod.svg";
import priceIcon from "../images/icons/price.svg";
import faqIcon from "../images/icons/faq.svg";
import searchIcon from "../images/icons/search.svg";

function IndexPage() {
  return (
    <Layout>
      <SEO
        title="Rozcestník"
      />

      <Section>
        <MainHeading heading="Rozcestník" section="PC poradna"></MainHeading>
        <UiHeading>Jak začít</UiHeading>
        <UiLinkContainer>
          <UiLink 
            headerText="1. Prohlédněte FAQ: často kladené dotazy"
            link="/faq/"
            image={faqIcon}
            />
          <span className="flex-grow ml-16 mr-4 border-b border-gray-200"></span>
          <UiLink 
            headerText="2. Zkuste vyhledat informace"
            link="/vyhledavani-informaci/"
            image={searchIcon}
            />
          <span className="flex-grow ml-16 mr-4 border-b border-gray-200"></span>
          <UiLink 
            headerText="3. Přidejte nový dotaz"
            link="/zalozeni-dotazu/"
            image={addIcon}
            />
        </UiLinkContainer>
        <UiHeading>Řešíme technické problémy</UiHeading>
        <UiLinkContainer>
          <UiLink 
            headerText="Jak řešit technický problém"
            link="/technicky-problem/"
            image={fixIcon}
            />
            <span className="flex-grow ml-16 mr-4 border-b border-gray-200"></span>
            <UiLink 
            headerText="Co s modrou obrazovkou smrti (BSOD)"
            link="/bsod/"
            image={bsodIcon}
            />
          </UiLinkContainer>
          <UiHeading>Pomůžeme s výběrem zboží</UiHeading>
          <UiLinkContainer>
            <UiLink 
            headerText="Jak se zeptat na doporučení hardware"
            descriptionText="Nákup nebo upgrade počítače, notebooku, mobilního telefonu"
            link="/doporuceni-hardware/"
            image={buyIcon}
            />
          </UiLinkContainer>
          <UiHeading>Bazar</UiHeading>
          <UiLinkContainer>
            <UiLink 
            headerText="Inzerce"
            descriptionText="Prodej a nákup bazarového zboží"
            link="/inzerce/"
            image={adIcon}
            />
            
        </UiLinkContainer>
      </Section>
    </Layout>
  );
}

export default IndexPage;
